















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Loader extends Vue {
  /**
   * Props
   */
  @Prop({ default: false, type: Boolean }) private small!: boolean;
  @Prop({ default: false, type: Boolean }) private large!: boolean;
  @Prop() private text?: string;

  /**
   * Getters
   */
  private get size(): number {
    if (this.small) {
      return 24;
    }

    if (this.large) {
      return 48;
    }

    return 32;
  }

  private get width(): number {
    if (this.small) {
      return 3;
    }

    if (this.large) {
      return 5;
    }

    return 4;
  }

  /**
   * Display getters
   */
  private get displayText(): boolean {
    return !!this.text;
  }
}
